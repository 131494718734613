import { Platform, Image, StyleSheet, Text, View } from 'react-native';
import { WebView } from 'react-native-webview';
import RenderHtml from 'react-native-render-html';
import { TouchableOpacity } from 'react-native';
import * as WebBrowser from 'expo-web-browser';
import { useEffect, useState } from 'react';
import { Pressable } from 'react-native';



function Card(props) {
    //console.log(props.body)
    const [imheight, setimheight] = useState(100);
    const [imwidth, setimwidth] = useState(100);
    const [imimg, setimimg] = useState(null);
    const [result, setResult] = useState(null);
    const _handlePressButtonAsync = async () => {
        let result = await WebBrowser.openBrowserAsync(props.source);
        setResult(result);
    };

    return (
        <Pressable
            style={styles.container}
            onPress={_handlePressButtonAsync}
        >
            <View
                style={styles.containerInside}
            >
                {props.imgurl ? <Image
                    style={[styles.imagestyle, { height: imheight, width: imwidth }]}
                    source={{
                        uri: props.imgurl,
                    }}
                    onLoad={(e) => {
                        if (e.nativeEvent.source) {
                            let width = e.nativeEvent.source.width;
                            let height = e.nativeEvent.source.height;
                            let ratio = 310 / width;
                            setimheight(height * ratio);
                            setimwidth(310);
                        }
                        if (e.nativeEvent.srcElement) {
                            let width = e.nativeEvent.srcElement.width;
                            let height = e.nativeEvent.srcElement.height;
                            let ratio = 310 / width;
                            setimheight(height * ratio);
                            setimwidth(310);
                        }
                    }}
                ></Image> : false}
                <Text
                    style={[
                        styles.textstyle, {
                            fontSize: 24,
                            marginVertical: 6
                        }]}
                >{props.title}</Text>
                <Text
                    style={[
                        styles.textstyle, {

                            marginVertical: 6
                        }]}
                >{props.date}</Text>
                {props.body ?
                    <Text
                        style={[
                            styles.textstyle, {
                                fontSize: 16,
                                lineHeight: 22,
                                marginVertical: 6
                            }]}
                    >
                        {props.body}
                    </Text> : false}
            </View>
        </Pressable >
    );
}
// npm install -g sharp-cli@^1.10.0

export default Card;
const styles = StyleSheet.create({
    container: {
        backgroundColor: '#020A1E',//'#0A2360',
        margin: 20,
        borderWidth: 1,
        borderColor: "#000000"
    },
    containerInside: {
        overflow: "hidden",
        width: "100%"
    },
    imagestyle: {
        width: 100,
        height: 100,
        resizeMode: "contain",
        alignSelf: "center"

    },
    textstyle: {
        padding: 10,
        color: "#ffffff",

    }

});
