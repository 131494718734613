import React, { useEffect, useState } from 'react';
import { SafeAreaView, ScrollView, StyleSheet } from 'react-native';
import SingleFeed from '../components/singlefeed';

export default function HaberScreen({ route }) {
    const { rssUrls } = route.params;
    const [feeds, setFeeds] = useState([]);
    console.log(route);
    useEffect(() => {
        const feedComponents = rssUrls.map((element, i) => (
            < SingleFeed key={i} url={element.url} name={element.name} />
        ));
        setFeeds(feedComponents);
    }, [rssUrls]);

    return (
        <SafeAreaView style={styles.container}>
            <ScrollView
                style={styles.containerScroll}
                horizontal={true}
            >
                {feeds}
            </ScrollView>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        // backgroundColor: '#0A2360',
        alignItems: 'center',
        justifyContent: 'center',
    },
    containerScroll: {
        height: '100%',
        width: '100%',
    },
});
