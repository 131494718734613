import * as React from 'react';
import { useEffect, useState } from 'react';
import { SafeAreaView, StyleSheet } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { Image } from 'react-native';
import { DefaultTheme } from '@react-navigation/native';
import { ActivityIndicator } from 'react-native';
import haberscreen from './screens/haberscreen';

const MyTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: '#111A2A',
  },
};

const Tab = createMaterialTopTabNavigator();

function TabScreen(key, name, iconUrl, rssUrls, tabbaractivetintcolor, tabbarinactivetintcolor) {

  return (
    <Tab.Screen
      key={key}
      options={{
        tabBarIcon: () => (
          <Image source={{ uri: iconUrl }} style={{ height: 24, width: 24, resizeMode: 'contain' }} />
        ),
        tabBarActiveTintColor: tabbaractivetintcolor,
        tabBarInactiveTintColor: tabbarinactivetintcolor,
      }}
      initialParams={{ rssUrls }}
      name={name}
      component={haberscreen}
    />
  );
}

export default function App() {
  const CORS_PROXY = "https://api.haberdolu.com/nocors/?url=";
  const [tabScreens, setTabScreens] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Track loading state
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(CORS_PROXY + 'https://api.haberdolu.com/haberler.json');
        const result = await response.json();
        const screens = Object.keys(result.sections).map((key) => {
          const tempdic = result.sections[key];
          const { iconUrl, rssUrls, name, tabbaractivetintcolor, tabbarinactivetintcolor } = tempdic;
          console.log(tempdic);
          return (
            TabScreen(
              key,
              name,
              iconUrl,
              rssUrls,
              tabbaractivetintcolor,
              tabbarinactivetintcolor,
            )
          );
        });
        setTabScreens(screens);
        setIsLoading(false); // Set loading to false when data is fetched
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchData();
  }, []);
  if (isLoading) {
    // Show loading indicator
    return (
      <SafeAreaView style={{ flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: '#111A2A' }}>
        <ActivityIndicator size="large" color="#ffffff" />
      </SafeAreaView>
    );
  }
  return (
    <NavigationContainer theme={MyTheme}>
      <Tab.Navigator
        screenOptions={{
          tabBarShowIcon: true,
          tabBarStyle: { backgroundColor: '#0A2360' },
          background: 'transparent',
          tabBarScrollEnabled: true,
          swipeEnabled: false,
          lazy: true,
          lazyPlaceholder: () => (
            <SafeAreaView style={{ height: 450, alignItems: 'center', justifyContent: 'center' }}>
              <ActivityIndicator size="large" color="#ffffff" />
            </SafeAreaView>
          ),
        }}
      >
        {tabScreens}
      </Tab.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    backgroundColor: '#111A2A',
  },
  containerScroll: {
    height: '100%',
    width: '100%',
  },
});
