import React, { useEffect, useState } from 'react';
import { FlatList, ActivityIndicator, StyleSheet, Text, View } from 'react-native';
import Card from './card';
import * as rssParser from 'react-native-rss-parser';
import { Platform } from 'react-native';
async function fetchRssData(url) {
    const CORS_PROXY = "https://api.haberdolu.com/nocors/?url=";
    const requestOptions = {
        method: 'GET',
        redirect: 'follow',
        origin: Platform.OS === 'web' ? window.location.protocol + '//' + window.location.host : ''
    };

    try {
        const response = await fetch(Platform.OS === 'web' ? CORS_PROXY + url : url, requestOptions);
        const responseData = await response.text();
        const rss = await rssParser.parse(responseData);
        console.log(rss.items);
        return rss.items || [];
    } catch (error) {
        console.error(error);
        return [];
    }
}

function renderCard(item) {
    let imageUrl = require('../assets/icon.png');
    if (item.enclosures.length > 0) {
        imageUrl = item.enclosures[0].url;
    }
    const imgurl = imageUrl;
    const link = item.links && item.links[0] && item.links[0].url;
    return (
        <Card
            imgurl={imgurl}
            title={item.title}
            date={item.published}
            source={link}
        />
    );

    return null;
}

function SingleFeed(props) {
    const [rssData, setRssData] = useState([]);
    useEffect(() => {
        fetchRssData(props.url).then((data) => {
            setRssData(data);
        });
    }, [props.url]);

    if (rssData.length > 0) {
        return (
            <View style={styles.container}>
                <FlatList
                    data={rssData}
                    renderItem={({ item }) => renderCard(item)}
                    keyExtractor={(item, index) => index.toString()}
                    ListHeaderComponent={
                        <View style={styles.header}>
                            <Text style={styles.headerText}>{props.name}</Text>
                        </View>
                    }
                />
            </View>
        );
    }
    return (
        <View style={styles.container}>
            <View style={styles.loadingContainer}>
                <ActivityIndicator />
                <Text style={styles.loadingText}>Yükleniyor...</Text>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        width: 350,
        margin: 20,
        borderWidth: 1,
        borderColor: "#000000",
        backgroundColor: "#111A2A",
    },
    header: {
        backgroundColor: "#000000",
        padding: 15,
        alignItems: "center",
        justifyContent: "center",
    },
    headerText: {
        fontSize: 24,
        fontWeight: "bold",
        color: "#fff",
    },
    loadingContainer: {
        margin: 30,
    },
    loadingText: {
        textAlign: "center",
        margin: 30,
        fontSize: 14,
        color: "blue",
    },
});

export default SingleFeed;
